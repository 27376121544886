<template>
  <el-dialog
    title="提示"
    :visible="true"
    width="400px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      <p>尚未填寫運送編號，是否仍要寄送 Email通知？</p>
    </div>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">確認</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { orderDeliveryStatus } from '@/config/ecommerce'
export default {
  name: 'ShipWarningDialog',
  methods: {
    confirm () {
      this.$emit('confirm', orderDeliveryStatus.shipped)
      this.$emit('close')
    },

    cancel () {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
