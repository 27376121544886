<template>
  <div class="form-info-item">
    <span class="title" :style="`width: ${width}px;`">{{ title }}</span>
    <span v-if="content" class="content">{{ content }}</span>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'FormInfoItem',
  props: ['title', 'content', 'width'],
}
</script>
<style lang="scss" scoped>
.form-info-item {
  display: flex;
  align-items: center;
  .title {
    @apply font-normal text-gray-80;
    flex-shrink: 0;
  }
  .content {
    @apply font-medium text-gray-100;
    width: 100%;
    word-break: break-all;
  }
}
</style>
