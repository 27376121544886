<template>
  <div class="ship-setting-dialog">
    <el-dialog
      title="編輯運送狀態"
      :visible="show"
      :close-on-click-modal="false"
      @close="onClose"
      @opened="onOpen"
    >
      <section>
        <el-form
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <el-form-item label="運送方式">
            {{ deliveryTypesName[delivery.type] }}
          </el-form-item>
          <el-form-item label="運送編號" prop="deliveryCode">
            <el-input v-model="formData.deliveryCode" placeholder="請輸入" />
          </el-form-item>
          <el-form-item label="運送狀態">
            {{ orderDeliveryStatusName[delivery.status] }}
          </el-form-item>
          <el-form-item label="運送狀態說明">
            {{ delivery.statusMsg || '-' }}
          </el-form-item>
          <el-form-item v-if="!showStoreAddress" label="個案地點">
            {{ delivery.address || '-' }}
          </el-form-item>
          <el-form-item label="收件人姓名">
            {{ delivery.receiver }}
          </el-form-item>
          <el-form-item label="收件人電話">
            {{ delivery.phone }}
          </el-form-item>
          <el-form-item v-if="showStoreName" label="收件門市">
            {{ storeName }}
          </el-form-item>
          <el-form-item v-if="showStoreAddress" label="收件門市地址">
            {{ delivery.address || '-' }}
          </el-form-item>
        </el-form>
      </section>

      <div slot="footer">
        <el-button plain @click="closeDialog"> 返回</el-button>
        <el-button type="primary" @click="onSubmit">儲存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import formUtils from '@/utils/form'
import {
  deliveryTypesName,
  deliveryConfigOptionsName,
  orderDeliveryStatusName,
  deliveryTypesConfig,
  deliveryConfigOptions,
} from '@/config/ecommerce'
import { noEmptyRules } from '@/validation'

export default {
  name: 'ShipSettingDialog',
  props: {
    show: { type: Boolean },
    deliveryCode: { type: String },
    delivery: { type: Object },
  },
  data () {
    return {
      orderDeliveryStatusName,
      formData: {
        deliveryCode: '',
      },
      formRules: {
        deliveryCode: [noEmptyRules()],
      },
      deliveryTypes: {
        ...deliveryConfigOptions,
        ...deliveryTypesConfig,
      },
      deliveryTypesName: {
        ...deliveryTypesName,
        ...deliveryConfigOptionsName,
      },
    }
  },
  computed: {
    isUseECPay () {
      const { type } = this.delivery
      return type === this.deliveryTypes.ecpay
    },
    showStoreName () {
      return this.isUseECPay
    },
    showStoreAddress () {
      return this.isUseECPay
    },
    storeName () {
      const { storeID, storeName } = this.delivery
      if (!storeID) return ''
      return `${storeID} ${storeName || ''}`
    },
  },
  methods: {
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData.deliveryCode = this.delivery.code
    },
    async onSubmit () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      this.$emit('confirm', this.formData)
    },
    onClose () {
      this.resetForm()
      this.closeDialog()
    },
    onOpen () {
      this.resetForm()
    },
    closeDialog () {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss">
.ship-setting-dialog {
  .el-form-item__content {
    @apply text-gray-100 text-sub;
  }
}
</style>
