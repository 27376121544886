<template>
  <el-dialog
    title="編輯發票狀態"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="發票狀態">
        <span>{{ formData.status.name }}</span>
      </el-form-item>

      <el-form-item label="發票種類">
        <span>{{ formData.type }}</span>
      </el-form-item>

      <el-form-item label="發票號碼" prop="code">
        <el-input v-model="formData.code" placeholder="請輸入" maxlength="12" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="updateInvoiceNo">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation/index'
import { invoiceStatusConfig } from '@/config/ecommerce'
import { UpdateInvoiceNo } from '@/api/ecommerce/order'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/form'
export default {
  name: 'InvoiceDialog',
  props: {
    invoice: Object,
    orderId: String,
  },
  data: () => ({
    invoiceStatusConfig,
    formData: {
      status: '',
      type: '',
      code: '',
    },
    formRules: {
      code: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },

  mounted () {
    this.syncData()
    console.log(this.invoice)
    this.formData.code = this.invoice.invoiceNo
  },
  methods: {
    syncData () {
      this.formData.status = invoiceStatusConfig[this.invoice.status]
      this.formData.type = this.invoice.type
    },

    async updateInvoiceNo () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [, err] = await UpdateInvoiceNo({
        shopId: this.shop,
        id: this.orderId,
        invoiceNo: this.formData.code,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新發票號碼成功！')
      this.$emit('updated')
      this.$emit('close')
    },
  },
}
</script>

<style></style>
