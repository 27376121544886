<template>
  <section class="statistics-info-wrapper">
    <div v-if="productTotalPromosPrice" class="statistics-info">
      <span class="name">活動優惠折抵</span>
      <span class="value">- $ {{ productTotalPromosPrice }}</span>
    </div>
    <div class="statistics-info">
      <span class="name">活動金額</span>
      <span class="value">$ {{ order.totalProductPrice }}</span>
    </div>
    <div class="statistics-info">
      <span class="name">運費</span>
      <span class="value">$ {{ order.totalDeliveryPrice }}</span>
    </div>
    <div v-if="orderTotalPromosPrice" class="statistics-info">
      <span class="name">結帳優惠</span>
      <span class="value">- $ {{ orderTotalPromosPrice }}</span>
    </div>
    <div v-if="order.useCashbackPrice" class="statistics-info">
      <span class="name">回饋金</span>
      <span class="value">- $ {{ order.useCashbackPrice }}</span>
    </div>
    <div v-if="promoCodeTotalPrice" class="statistics-info">
      <span class="name">折扣碼優惠</span>
      <span class="value">- $ {{ promoCodeTotalPrice }} <br> {{ promoCodeUse }}</span>
    </div>
    <div v-if="promoCodeTotalPrice" class="statistics-info">
      <span class="name">儲值金</span>
      <span class="value">- $ {{ order.walletPrice }}</span>
    </div>
    <div class="statistics-info">
      <span class="name" style="color: var(--primary-100)">結帳金額</span>
      <span class="value" style="color: var(--primary-100)">$ {{ order.paymentPrice }}</span>
    </div>
    <template v-if="get(order, 'EcommerceOrderAddCashback.amount')">
      <el-divider />
      <div class="statistics-info">
        <span class="name">訂單完成後獲得回饋金</span>
        <span class="value">$ {{ get(order, 'EcommerceOrderAddCashback.amount') }}</span>
      </div>
    </template>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { filter, map, reduce, get } from 'lodash'

const props = defineProps({
  order: {
    type: Object,
    default: () => ({}),
  },
})

// 折扣碼優惠
const promoCodeTotalPrice = computed(() => {
  const orderPromoCode = filter(props.order.EcommerceOrderPromoByOrders, promo => promo.EcommercePromoCodeId)
  const productPromoCode = filter(props.order.EcommerceOrderPromoByProducts, promo => promo.EcommercePromoCodeId)
  const productCodeSave = productPromoCode.length ? productPromoCode[0].saveTotal : 0
  const orderCodeSave = orderPromoCode.length ? orderPromoCode[0].saveTotal : 0
  return productCodeSave + orderCodeSave
})

// 結帳優惠
const orderTotalPromosPrice = computed(() => {
  if (!props.order.EcommerceOrderPromoByOrders) return 0

  const saves = map(
    filter(props.order.EcommerceOrderPromoByOrders, promo => !promo.EcommercePromoCodeId),
    'saveTotal',
  )
  const total = reduce(saves, (total, save) => {
    return total + save
  }, 0)
  return total
})

// 活動優惠折抵
const productTotalPromosPrice = computed(() => {
  if (!props.order.EcommerceOrderPromoByProducts) return 0

  const saves = map(
    filter(props.order.EcommerceOrderPromoByProducts, promo => !promo.EcommercePromoCodeId),
    'saveTotal',
  )
  const total = reduce(saves, (total, save) => {
    return total + save
  }, 0)
  return total
})

</script>

<style scoped lang="scss">
.statistics-info-wrapper {
  @apply mt-[12px] grid grid-flow-row place-items-end gap-[8px] py-[16px] px-[40px];
  background: white;
}

.statistics-info {
  @apply flex ;
  .name {
    @apply text-right text-gray-80 font-normal;
  }
  .value{
    @apply text-right text-gray-100 font-medium w-[100px];
    word-break: break-all;padding-left: 1.25rem
  }
}

::v-deep .el-divider--horizontal {
@apply my-[8px];
}
</style>
