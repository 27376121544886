export function CreateFormPost (action, data, { target = '', method = 'post' }) {
  const form = document.createElement('form')
  form.style.display = 'none'
  form.method = method
  form.action = action
  form.target = target

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = data[key]

      form.appendChild(hiddenField)
    }
  }
  return form
}
