<template>
  <el-dialog
    title="提示"
    width="400"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      *發票期別的次月14日23:59前，皆可作廢發票，若超過時限請選擇折讓發票 <br>
      *僅能全額作廢，恕無法拆分金額
    </div>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="confirm">確定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'InvoiceCancelDialog',
  methods: {
    confirm () {
      this.$emit('confirm')
      this.$emit('close')
    },
  },
}
</script>

<style></style>
