<template>
  <div ref="formInfo" class="form-info" :style="`gap: ${gap}px;`">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'FormInfo',
  props: ['gap', 'width'],
}
</script>
<style lang="scss">
.form-info {
  @apply flex flex-col;
  // display: grid;
  // grid-auto-flow: row;
}
</style>
