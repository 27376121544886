<template>
  <EcommerceOrderInfoBlock title="發票資訊" :btnDisabled="isEmpty(invoice) || isCanceled || invoice.status !== 'adminInvoiced'" @edit="invoiceSettingDialog = true">
    <FormInfo gap="12" class="order-info invoice-info">
      <div class="flex justify-between items-center">
        <!-- :content="invoiceStatusConfig[invoice.status].name" -->
        <FormInfoItem width="120" title="發票狀態">
          <el-select
            v-model="invoiceStatus"
            :disabled="!invoice.status || isCanceled"
            class="status-select"
            placeholder="請選擇"
            @change="changeStatus"
          >
            <el-option
              v-for="status in invoiceStatusConfig"
              :key="status.value"
              :disabled="!avaliableStatus.includes(status.value)"
              :label="status.name"
              :value="status.value"
            />
          </el-select>
        </FormInfoItem>
        <!-- <el-tag color="#BBABE3" style="color: white;">{{ statusTag }}</el-tag> -->
        <!-- <el-button
          :disabled="
            isEmpty(invoice) || isCanceled || invoice.status !== 'adminInvoiced'
          "
          type="text"
          class="edit-btn"
          @click="invoiceSettingDialog = true"
        >
          編輯
        </el-button> -->
      </div>
      <FormInfoItem
        width="120"
        title="發票種類"
        :content="invoice.type ? invoice.type : '-'"
      />

      <FormInfo v-if="invoice.type !== 'B2C'" gap="12">
        <FormInfoItem
          v-if="invoice.customerName"
          width="120"
          title="公司抬頭"
          :content="invoice.customerName"
        />
        <FormInfoItem
          v-if="invoice.customerIdentifier"
          width="120"
          title="統一編號"
          :content="invoice.customerIdentifier"
        />
      </FormInfo>

      <FormInfoItem
        width="120"
        title="發票號碼"
        :content="invoice.invoiceNo ? invoice.invoiceNo : '-'"
      />
      <!-- <FormInfoItem
          v-if="invoice.useLoveCode"
          width="120"
          title="捐贈碼"
          content="123"
        />
        <div v-if="invoice.useCarrier">
          <FormInfoItem
            width="120"
            title="載具類別"
            :content="invoice.carrierType"
          />
          <FormInfoItem
            width="120"
            title="載具號碼"
            :content="invoice.carrierNum"
          />
        </div> -->

      <InvoiceCancelDialog
        v-if="cancelDialog"
        @close=";(cancelDialog = false), (invoiceStatus = invoice.status)"
        @confirm="updateInvoiceStatus"
      />
      <InvoiceAllowance
        v-if="allowanceDialog"
        @close=";(allowanceDialog = false), (invoiceStatus = invoice.status)"
        @confirm="updateInvoiceStatus"
      />
      <ManualInvoiceDialog
        v-if="invoiceSettingDialog"
        :invoice="invoice"
        :orderId="orderId"
        @close="invoiceSettingDialog = false"
        @updated="$emit('updated')"
      />
    </FormInfo>
  </EcommerceOrderInfoBlock>
</template>

<script>
import EcommerceOrderInfoBlock from './OrderInfoBlock.vue'
import ManualInvoiceDialog from '@/views/Ecommerce/Dialog/ManualInvoiceDialog.vue'
import InvoiceAllowance from '@/views/Ecommerce/Dialog/InvoiceAllowance.vue'
import InvoiceCancelDialog from '@/views/Ecommerce/Dialog/InvoiceCancelDialog.vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import { invoiceStatusConfig } from '@/config/ecommerce'
import { UpdateInvoiceStatus } from '@/api/ecommerce/order'
import { mapGetters } from 'vuex'
import { isEmptyObj } from '@/utils/helper'

export default {
  name: 'InvoiceInfo',
  components: {
    FormInfo,
    FormInfoItem,
    InvoiceCancelDialog,
    InvoiceAllowance,
    ManualInvoiceDialog,
    EcommerceOrderInfoBlock,
  },
  props: ['invoice', 'orderId', 'isCanceled', 'config'],
  data: () => ({
    invoiceSettingDialog: false,
    invoiceStatusConfig,
    invoiceStatus: '',
    cancelDialog: false,
    allowanceDialog: false,
  }),

  computed: {
    ...mapGetters(['shop']),
    statusTag () {
      let title = ''
      if (!this.invoiceStatus) title = '系統商尚未發送'
      else if (this.invoiceStatus === 'uninvoiced') title = '系統商尚未發送'
      else if (this.invoiceStatus !== 'uninvoiced') title = '系統商已發發票'
      return title
    },

    avaliableStatus () {
      let list = []
      if (this.invoiceStatus === 'uninvoiced') {
        if (this.config.enableOrderInvoiceApi) {
          list = [
            'systemInvoiced',
            'adminInvoiced',
            'allInvalid',
            'allAllowance',
          ]
        } else list = ['adminInvoiced', 'allInvalid', 'allAllowance']
      } else if (this.invoiceStatus === 'systemInvoiced') { list = ['allInvalid', 'allAllowance'] } else if (this.invoiceStatus === 'adminInvoiced') list = []
      else if (this.invoiceStatus === 'allInvalid') list = []
      else if (this.invoiceStatus === 'allAllowance') list = []
      return list
    },
  },

  watch: {
    invoice () {
      this.invoiceStatus = this.invoice.status
      if (!this.invoiceStatus) this.invoiceStatus = 'uninvoiced'
    },
  },

  mounted () {
    console.log(this.invoice)
    this.invoiceStatus = this.invoice.status
    if (!this.invoiceStatus) this.invoiceStatus = 'uninvoiced'
  },
  methods: {
    isEmpty (target) {
      return isEmptyObj(target)
    },
    async changeStatus (val) {
      this.$emit('loading')
      if (val === 'allInvalid') this.cancelDialog = true
      else if (val === 'allAllowance') this.allowanceDialog = true
      else await this.updateInvoiceStatus()
      this.$emit('loaded')
    },

    async updateInvoiceStatus () {
      const [, err] = await UpdateInvoiceStatus({
        shopId: this.shop,
        id: this.orderId,
        status: this.invoiceStatus,
      })
      if (err) {
        this.$message.error(err)
        this.invoiceStatus = this.invoice.status
        return
      }
      this.$message.success('更新發票狀態成功！')
      this.$emit('updated')
    },
  },
}
</script>

<style scoped lang="scss">
.invoice-info {
  @apply py-[27.5px] px-[20px];
}
::v-deep .status-select {
  width: 150px !important;
  .el-input,
  .el-select {
    width: 150px !important;
  }
}

.edit-btn {
  color: var(--primary-100);
  cursor: pointer;
  text-decoration: underline;

  &.is-disabled {
    color: var(--black-4);
  }
}
</style>
