<template>
  <el-dialog
    title="編輯備註"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      <el-form label-position="top" :model="formData">
        <el-form-item label="告知消費者備註">
          <el-input v-model="formData.shopNote" type="textarea" :rows="3" maxlength="1000" show-word-limit />
        </el-form-item>
        <el-form-item label="店家內部備註">
          <el-input v-model="formData.adminNote" type="textarea" :rows="3" maxlength="1000" show-word-limit />
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UpdateShopNote, UpdateAdminNote } from '@/api/ecommerce/order'
import { mapGetters } from 'vuex'
export default {
  name: 'NoteEditDialog',
  props: ['order'],
  data: () => ({
    formData: {
      adminNote: '',
      shopNote: '',
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  mounted () {
    this.formData.adminNote = this.order.EcommerceOrderNoteAdmin.text
    this.formData.shopNote = this.order.EcommerceOrderNoteShop.text
  },
  methods: {
    async confirm () {
      if (!await this.updateShopNote()) return
      if (!await this.updateAdminNote()) return
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    async updateShopNote () {
      const [, err] = await UpdateShopNote({
        shopId: this.shop,
        id: this.order.id,
        text: this.formData.shopNote,
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return true
    },
    async updateAdminNote () {
      const [, err] = await UpdateAdminNote({
        shopId: this.shop,
        id: this.order.id,
        text: this.formData.adminNote,
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return true
    },

  },
}
</script>

<style></style>
